<template>
    <div class="bmi" :class="{ 'state-collapsed': stateCollapsed }">
        <h3>Slik regner du ut din eksakte BMI (kg/m<sup>2</sup>):</h3>
        <p>Du kan regne ut din egen BMI ved å fylle ut feltene nedenfor. Høyde må oppgis i cm.</p>
        <table class="table table-bmi">
            <tr>
                <td><input type="text" placeholder="vekt i kg" v-model="weight" /></td>
                <td rowspan="2">= {{ calculateBMI() }}</td>
            </tr>
            <tr>
                <td><input type="text" placeholder="høyde x høyde" v-model="height" /></td>
            </tr>
        </table>
        <table class="table table-bmi">
            <tr>
                <td>Eksempel:</td>
                <td></td>
            </tr>
            <tr>
                <td rowspan="2">Eks:</td>
                <td>91 kg</td>
                <td rowspan="2">= 29 (BMI)</td>
            </tr>
            <tr>
                <td>1,78 m x 1,78 m</td>
            </tr>
        </table>
    </div>
</template>
<script>
    export default {
        props: {
            stateCollapsed: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data() {
            return {
                weight: '',
                height: '',
            };
        },

        methods: {
            calculateBMI() {
                const height = Number(this.height.replace(',', '.')) / 100;
                const weight = Number(this.weight.replace(',', '.'));

                if(!height || !weight || height <= 0 || weight <= 0) {
                    return 'BMI';
                }

                if(height > 300) {
                    this.height = 300;
                }

                if(weight > 1000) {
                    this.weight = 1000;
                }

                const bmi = Number(weight / (height * height)).toFixed(1).toString();

                if(bmi >= 100) {
                    return '≥100';
                }

                return bmi.replace('.', ',');
            },
        },
    };
</script>