var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'is-collapsable': _vm.isCollapsable(_vm.index),
    'is-collapsed': _vm.onepage && !_vm.stateCollapsed,
  }},[_vm._l((_vm.children),function(child,i){return [(_vm.isBMI(child))?_c('BMI',{key:_vm.index + '_' + i,attrs:{"stateCollapsed":_vm.isCollapsed(_vm.index, i)}}):(_vm.isTOC(child))?_c('TableOfContents',{key:_vm.index + '_' + i,attrs:{"elements":_vm.retrieveTableOfContents(),"stateCollapsed":_vm.isCollapsed(_vm.index, i)}}):(child._type === 'alignedImage')?_c('div',{key:_vm.index + '_' + i,staticClass:"aligned-image",class:_vm.getAlignedImageClasses(child, false, _vm.isCollapsed(_vm.index, i)),on:{"click":function($event){return _vm.handleClick(child)}}},[(child.mainImage)?_c('div',{staticClass:"image",class:_vm.getAlignedImageClasses(child, true, _vm.isCollapsed(_vm.index, i))},[_c('img',{attrs:{"src":_vm.getAlignedImageUrl(child),"alt":child.title,"title":child.title}})]):_vm._e(),_c('div',{staticClass:"text",class:{
          'text-left': !child.alignmentText || child.alignmentText === 'left',
          'text-center': child.alignmentText === 'center',
          'text-right': child.alignmentText === 'right',
        }},[(child.visible && child.title)?_c('strong',{staticClass:"title"},[_vm._v(_vm._s(child.title))]):_vm._e(),_vm._l((child.text),function(c,ind){return _c('VHTML',{key:ind,attrs:{"html":_vm.render(c, ind)}})})],2)]):(child._type === 'alignedImageWrapper')?_c('div',{key:_vm.index + '_' + i,staticClass:"grid auto-template-rows padding-bottom",class:{ 'state-collapsed': _vm.isCollapsed(_vm.index, i) }},_vm._l((child.children),function(alignedImage,alignedImageIndex){
        var _obj;
return _c('div',{key:alignedImageIndex,staticClass:"col-sm-12",class:_vm.getAlignedImageWrapperClasses(child)},[_c('div',{staticClass:"wrapped-aligned-image"},[_c('div',{staticClass:"aligned-image",class:_vm.getAlignedImageClasses(
                alignedImage,
                false,
                _vm.isCollapsed(_vm.index, i)
              ),on:{"click":function($event){return _vm.handleClick(alignedImage)}}},[(alignedImage.mainImage)?_c('div',{staticClass:"image",class:_vm.getAlignedImageClasses(
                  alignedImage,
                  true,
                  _vm.isCollapsed(_vm.index, i)
                )},[_c('img',{attrs:{"src":_vm.getAlignedImageUrl(alignedImage),"alt":alignedImage.title,"title":alignedImage.title}})]):_c('div',{staticClass:"icon"},[_c('i',{staticClass:"fa-regular",class:( _obj = {}, _obj[alignedImage.icon] = true, _obj )})]),_c('div',{staticClass:"text",class:{
                'text-left':
                  !alignedImage.alignmentText ||
                  alignedImage.alignmentText === 'left',
                'text-center': alignedImage.alignmentText === 'center',
                'text-right': alignedImage.alignmentText === 'right',
              }},[(alignedImage.visible && alignedImage.title)?_c('strong',{staticClass:"title"},[_vm._v(_vm._s(alignedImage.title))]):_vm._e(),_vm._l((alignedImage.text),function(c,ind){return _c('VHTML',{key:ind,attrs:{"html":_vm.render(c, ind)}})})],2)])])])}),0):(child._type === 'pdf')?_c('div',{key:(_vm.index + "_" + i)},[_c('div',{staticClass:"pdf"},[_c('div',{staticClass:"header",on:{"click":function($event){return _vm.goToFile(child)}}},[_c('span',[_vm._v(_vm._s(child.title))])]),_c('img',{attrs:{"src":_vm.retrieveImage(child.image),"alt":child.title},on:{"click":function($event){return _vm.goToFile(child)}}})])]):(child._type === 'pdfWrapper')?_c('div',{key:(_vm.index + "_" + i),staticClass:"grid auto-template-rows padding-bottom"},_vm._l((child.children),function(pdf,pdfIndex){return _c('div',{key:pdfIndex,staticClass:"col-sm-12",class:_vm.getPdfWrapperClasses(child)},[_c('div',{staticClass:"pdf"},[_c('div',{staticClass:"header",on:{"click":function($event){return _vm.goToFile(pdf)}}},[_c('span',[_vm._v(_vm._s(pdf.title))])]),_c('img',{attrs:{"src":_vm.retrieveImage(pdf.image),"alt":pdf.title},on:{"click":function($event){return _vm.goToFile(pdf)}}})])])}),0):(child._type === 'priceWrapper')?_c('div',{key:(_vm.index + "_" + i),staticClass:"grid priceWrapper"},_vm._l((child.children),function(price,priceIndex){return _c('div',{key:priceIndex,staticClass:"col-sm-12",class:{
          'col-4': price.columns === 3 || !price.columns,
          'col-6': price.columns === 2,
          'col-12': price.columns === 1,
        }},[_c('div',{staticClass:"price"},[_c('div',[_c('h3',[_vm._v(_vm._s(price.title))]),(price.price && price.monthly)?[_c('p',[_vm._v(_vm._s(_vm.formatNumber(price.price))+" per måned")]),_c('p',{staticClass:"total_price"},[_vm._v(" Totalt "+_vm._s(_vm.formatNumber(price.price * price.months))+" ")])]:(price.price)?[_c('p',[_vm._v(_vm._s(_vm.formatNumber(price.price)))])]:_vm._e(),(price.description)?_c('p',[_vm._v(_vm._s(price.description))]):_vm._e()],2),_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.goToPrice(price.link)}}},[_c('span',[_vm._v(_vm._s(price.text))]),_c('i',{staticClass:"gn gn-icons arrow-link-white"})])])])}),0):(_vm.isHeaderElement(child))?_c('h2',{key:_vm.index + '_' + i,class:{ header: _vm.element === 'index' && _vm.index === 0 },attrs:{"id":'header-' + _vm.index},on:{"click":function($event){return _vm.toggleCollapse()}}},[_c('VHTML',{attrs:{"html":_vm.getHeaderElement(child, _vm.index)}})],1):(_vm.isHeaderElement(child))?_c('VHTML',{key:_vm.index + '_' + i,attrs:{"html":_vm.render(child, _vm.index, _vm.isCollapsed(_vm.index, i))}}):_c('VHTML',{key:_vm.index + '_' + i,attrs:{"html":_vm.render(child, _vm.index, _vm.isCollapsed(_vm.index, i))}})]}),(_vm.onepage && _vm.isCollapsable(_vm.index))?_c('p',{staticClass:"read-more",on:{"click":function($event){return _vm.toggleCollapse()}}},[_vm._v(" "+_vm._s(("Les " + (!_vm.stateCollapsed ? "mer" : "mindre") + "..."))+" ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }