<template>
    <div v-if="elements" class="table-of-contents" :class="retrieveClasses()">
        <div class="top" @click="clickTableOfContents()">
            <h3>Innholdsfortegnelse</h3>
        </div>
        <div class="bottom">
            <ol>
                <li v-for="(content, index) of elements" :key="index">
                    <a href="javascript: void(0);" @click="goToElement(content.index);" v-html="content.text"></a>
                </li>
            </ol>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            elements: {
                type: Array,
                required: true,
            },

            stateCollapsed: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data: () => {
            return {
                showTableOfContents: false,
            };
        },
        
        methods: {
            clickTableOfContents() {
                this.showTableOfContents = !this.showTableOfContents
            },

            retrieveClasses() {
                const classes = [];

                if(!this.showTableOfContents) {
                    classes.push('collapsed');
                }

                if(this.stateCollapsed) {
                    classes.push('state-collapsed');
                }

                return classes.join(' ');
            },

            goToElement(element) {
                const el = document.getElementById(element);

                window.scrollTo({
                    top: el.offsetTop,
                    left: 0,
                    behavior: 'smooth',
                });
            },
        },

        mounted() {
        },
    }
</script>