<template>
  <div
    :class="{
      'is-collapsable': isCollapsable(index),
      'is-collapsed': onepage && !stateCollapsed,
    }"
  >
    <template v-for="(child, i) in children">
      <BMI
        v-if="isBMI(child)"
        :key="index + '_' + i"
        :stateCollapsed="isCollapsed(index, i)"
      />
      <TableOfContents
        v-else-if="isTOC(child)"
        :elements="retrieveTableOfContents()"
        :key="index + '_' + i"
        :stateCollapsed="isCollapsed(index, i)"
      />
      <!-- Aligned image -->
      <div
        v-else-if="child._type === 'alignedImage'"
        class="aligned-image"
        :class="getAlignedImageClasses(child, false, isCollapsed(index, i))"
        @click="handleClick(child)"
        :key="index + '_' + i"
      >
        <div
          v-if="child.mainImage"
          class="image"
          :class="getAlignedImageClasses(child, true, isCollapsed(index, i))"
        >
          <img
            :src="getAlignedImageUrl(child)"
            :alt="child.title"
            :title="child.title"
          />
        </div>
        <div
          class="text"
          :class="{
            'text-left': !child.alignmentText || child.alignmentText === 'left',
            'text-center': child.alignmentText === 'center',
            'text-right': child.alignmentText === 'right',
          }"
        >
          <strong v-if="child.visible && child.title" class="title">{{
            child.title
          }}</strong>
          <VHTML
            v-for="(c, ind) in child.text"
            :html="render(c, ind)"
            :key="ind"
          />
        </div>
      </div>
      <div
        v-else-if="child._type === 'alignedImageWrapper'"
        class="grid auto-template-rows padding-bottom"
        :key="index + '_' + i"
        :class="{ 'state-collapsed': isCollapsed(index, i) }"
      >
        <div
          class="col-sm-12"
          :class="getAlignedImageWrapperClasses(child)"
          v-for="(alignedImage, alignedImageIndex) in child.children"
          :key="alignedImageIndex"
        >
          <div class="wrapped-aligned-image">
            <div
              class="aligned-image"
              :class="
                getAlignedImageClasses(
                  alignedImage,
                  false,
                  isCollapsed(index, i)
                )
              "
              @click="handleClick(alignedImage)"
            >
              <div
                v-if="alignedImage.mainImage"
                class="image"
                :class="
                  getAlignedImageClasses(
                    alignedImage,
                    true,
                    isCollapsed(index, i)
                  )
                "
              >
                <img
                  :src="getAlignedImageUrl(alignedImage)"
                  :alt="alignedImage.title"
                  :title="alignedImage.title"
                />
              </div>
              <div class="icon" v-else>
                <i class="fa-regular" :class="{ [alignedImage.icon]: true }" />
              </div>
              <div
                class="text"
                :class="{
                  'text-left':
                    !alignedImage.alignmentText ||
                    alignedImage.alignmentText === 'left',
                  'text-center': alignedImage.alignmentText === 'center',
                  'text-right': alignedImage.alignmentText === 'right',
                }"
              >
                <strong
                  v-if="alignedImage.visible && alignedImage.title"
                  class="title"
                  >{{ alignedImage.title }}</strong
                >
                <VHTML
                  v-for="(c, ind) in alignedImage.text"
                  :html="render(c, ind)"
                  :key="ind"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Aligned Image End -->
      <!-- PDF -->
      <div v-else-if="child._type === 'pdf'" :key="`${index}_${i}`">
        <div class="pdf">
          <div class="header" @click="goToFile(child)">
            <span>{{ child.title }}</span>
          </div>
          <img
            :src="retrieveImage(child.image)"
            :alt="child.title"
            @click="goToFile(child)"
          />
        </div>
      </div>
      <div
        v-else-if="child._type === 'pdfWrapper'"
        class="grid auto-template-rows padding-bottom"
        :key="`${index}_${i}`"
      >
        <div
          class="col-sm-12"
          :class="getPdfWrapperClasses(child)"
          v-for="(pdf, pdfIndex) in child.children"
          :key="pdfIndex"
        >
          <div class="pdf">
            <div class="header" @click="goToFile(pdf)">
              <span>{{ pdf.title }}</span>
            </div>
            <img
              :src="retrieveImage(pdf.image)"
              :alt="pdf.title"
              @click="goToFile(pdf)"
            />
          </div>
        </div>
      </div>
      <!-- End PDF -->
      <!-- Price -->
      <div
        v-else-if="child._type === 'priceWrapper'"
        class="grid priceWrapper"
        :key="`${index}_${i}`"
      >
        <div
          v-for="(price, priceIndex) in child.children"
          :key="priceIndex"
          :class="{
            'col-4': price.columns === 3 || !price.columns,
            'col-6': price.columns === 2,
            'col-12': price.columns === 1,
          }"
          class="col-sm-12"
        >
          <div class="price">
            <div>
              <h3>{{ price.title }}</h3>
              <template v-if="price.price && price.monthly">
                <p>{{ formatNumber(price.price) }} per måned</p>
                <p class="total_price">
                  Totalt {{ formatNumber(price.price * price.months) }}
                </p>
              </template>
              <template v-else-if="price.price">
                <p>{{ formatNumber(price.price) }}</p>
              </template>
              <p v-if="price.description">{{ price.description }}</p>
            </div>
            <button type="button" @click="goToPrice(price.link)">
              <span>{{ price.text }}</span>
              <i class="gn gn-icons arrow-link-white"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- End Price -->
      <h2
        v-else-if="isHeaderElement(child)"
        :key="index + '_' + i"
        @click="toggleCollapse()"
        :id="'header-' + index"
        :class="{ header: element === 'index' && index === 0 }"
      >
        <VHTML :html="getHeaderElement(child, index)" />
      </h2>
      <VHTML
        v-else-if="isHeaderElement(child)"
        :html="render(child, index, isCollapsed(index, i))"
        :key="index + '_' + i"
      />
      <VHTML
        v-else
        :html="render(child, index, isCollapsed(index, i))"
        :key="index + '_' + i"
      />
    </template>
    <p
      class="read-more"
      v-if="onepage && isCollapsable(index)"
      @click="toggleCollapse()"
    >
      {{ `Les ${!stateCollapsed ? "mer" : "mindre"}...` }}
    </p>
  </div>
</template>
<script>
import { Component as VHTML } from "@staszek998/v-html";

import sanity from "../sanity";
import imageUrlBuilder from "@sanity/image-url";
import { getFile, buildFileUrl } from "@sanity/asset-utils";

const imageBuilder = imageUrlBuilder(sanity);

const blocksToHtml = require("@sanity/block-content-to-html");

import BMI from "./BMI";
import TableOfContents from "./TableOfContents";

export default {
  components: {
    BMI,
    TableOfContents,
    VHTML,
  },
  props: {
    children: {
      type: Array,
      required: true,
    },

    blocks: {
      type: Array,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    element: {
      type: String,
      required: true,
    },

    overwrite: {
      type: Array,
      required: false,
    },
    onepage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      stateCollapsed: false,
    };
  },

  methods: {
    isBMI(block) {
      return this.checkShortCode(block, "bmi");
    },

    isTOC(block) {
      return this.checkShortCode(block, "toc");
    },

    isHeaderElement(block) {
      return block?._type === "block" && block?.style === "h2";
    },

    getHeaderElement(block, index) {
      const render = this.render(block, index);

      const r = new RegExp(/<h2 (.*?)>(.*?)<\/h2>/g);
      let findHeader;
      while (null != (findHeader = r.exec(render))) {
        return findHeader[2];
      }
    },

    checkShortCode(block, shortcode) {
      return (
        block?._type === "block" &&
        block?.style === "normal" &&
        block?.children[0]?.text === `[[${shortcode}]]`
      );
    },

    getOverwrite(index) {
      const overwrite = this.overwrite?.find(
        (o) => parseInt(o.index) === parseInt(index)
      );

      return parseInt(overwrite?.paragraphs || 2);
    },

    toggleCollapse() {
      this.stateCollapsed = !this.stateCollapsed;
    },

    isCollapsable(index, i = undefined) {
      if (!this.onepage) {
        return false;
      }

      const overwrite = this.getOverwrite(index);

      if (i === undefined) {
        return (
          index > 0 && overwrite !== -1 && this.children.length > overwrite
        );
      }

      return index > 0 && overwrite !== -1 && i - 1 >= overwrite;
    },

    isCollapsed(index, i) {
      return this.isCollapsable(index, i) && !this.stateCollapsed;
    },

    render(block, index = undefined, isCollapsed = false) {
      const h = blocksToHtml.h;

      let k = 0;
      return blocksToHtml({
        blocks: block,
        serializers: {
          types: {
            code: (props) =>
              h(
                "pre",
                {
                  className: props.node.language,
                },
                h("code", props.node.code)
              ),

            team: (props) => {
              return h(
                "div",
                {
                  className: "col-4 col-sm-12 employee",
                },
                [
                  h("div", {
                    className: "image",
                    style: `background-image: url('${this.getImageUrl(
                      props.node
                    )}')`,
                  }),
                  h("h3", props.node.name),
                  h("div", {
                    innerHTML: this.render(props.node.bio, true),
                  }),
                ]
              );
            },

            teamWrapper: (props) =>
              h(
                "div",
                {
                  className: `team grid ${
                    isCollapsed ? "state-collapsed" : ""
                  }`,
                },
                props.children
              ),

            section: (props) => {
              return h(
                "div",
                {
                  className: "col-6 col-sm-12 section",
                },
                [
                  h(
                    "h2",
                    {
                      className: "section-hero",
                    },
                    props.node.title
                  ),
                  h("div", {
                    innerHTML: this.render(props.node.body, true),
                  }),
                ]
              );
            },

            sectionWrapper: (props) =>
              h(
                "div",
                {
                  className: `sections grid ${
                    isCollapsed ? "state-collapsed" : ""
                  }`,
                },
                props.children
              ),

            button: (props) =>
              h(
                "div",
                {
                  className: `${
                    props.node._count >= 3
                      ? "col-4 col-sm-12"
                      : props.node._count === 2
                      ? "col-6 col-sm-12"
                      : "col-12 flex flex-group"
                  }`,
                },
                [
                  h(
                    "a",
                    {
                      className: [
                        "btn",
                        !props.node.small ? "btn-w-icon" : "",
                        props.node.body ? "btn-w-body" : "",
                      ]
                        .filter((v) => !!v)
                        .join(" "),
                      href: props.node.href || "",
                      target: props.node.blank ? "_blank" : "_self",
                    },
                    !props.node.small
                      ? [
                          h(
                            "div",
                            {
                              className: "text",
                            },
                            [
                              h(
                                "h2",
                                {
                                  className: "mb-0",
                                },
                                props.node.title
                              ),
                              props.node.text &&
                                h("p", {
                                  innerHTML: props.node.text,
                                }),
                            ]
                          ),
                          h("i", {
                            className: "gn gn-icons arrow-link",
                          }),
                        ]
                      : [
                          h("span", {}, props.node.title),
                          h("i", {
                            className: "gn gn-icons arrow-link",
                          }),
                        ]
                  ),
                  props.node.body &&
                    h("div", {
                      innerHTML: this.render(props.node.body),
                    }),
                ]
              ),

            buttonWrapper: (props) =>
              h(
                "div",
                {
                  className: "grid",
                },
                props.children
              ),

            topic: (props) =>
              h(
                "div",
                {
                  className: ["topic", props.node.reversed && "reversed"]
                    .filter((v) => !!v)
                    .join(" "),
                },
                [
                  h("img", {
                    src: this.getImageAsset(props.node.mainImage),
                  }),
                  h(
                    "div",
                    {
                      className: "content",
                    },
                    [
                      h("h2", {}, props.node.title),
                      h("div", {
                        innerHTML: this.render(props.node.body),
                      }),
                    ]
                  ),
                ]
              ),

            category: (props) =>
              h(
                "a",
                {
                  className: "col-6 col-sm-12 section category",
                  href: `/category/${
                    props.node.slug && props.node.slug.current
                      ? props.node.slug.current
                      : props.node.id
                  }/`,
                },
                h("img", {
                  src: this.getImageUrl(props.node, "image"),
                  alt: props.node.title,
                  title: props.node.title,
                }),
                h("h3", props.node.title),
                h("p", props.node.description)
              ),

            categoryWrapper: (props) =>
              h(
                "div",
                {
                  className: `sections grid ${
                    isCollapsed ? "state-collapsed" : ""
                  }`,
                },
                props.children
              ),

            block: (props) => {
              const style = props.node.style || "normal";

              if (style == "h1") {
                return h(
                  "h1",
                  {
                    className: `${isCollapsed ? "state-collapsed" : ""}`,
                  },
                  props.children
                );
              }

              if (style == "h2") {
                k++;

                return h(
                  "h2",
                  {
                    id: `header-${index === undefined ? k : index}`,
                    className: `${
                      this.element === "index" && index === 0 ? "header" : ""
                    } ${isCollapsed ? "state-collapsed" : ""}`,
                  },
                  props.children
                );
              }

              if (style == "h3") {
                return h(
                  "h3",
                  {
                    className: `${isCollapsed ? "state-collapsed" : ""}`,
                  },
                  props.children
                );
              }

              if (style == "h4") {
                return h(
                  "h4",
                  {
                    className: `${isCollapsed ? "state-collapsed" : ""}`,
                  },
                  props.children
                );
              }

              if (style == "h5") {
                return h(
                  "h5",
                  {
                    className: `${isCollapsed ? "state-collapsed" : ""}`,
                  },
                  props.children
                );
              }

              if (style == "h6") {
                return h(
                  "h6",
                  {
                    className: `${isCollapsed ? "state-collapsed" : ""}`,
                  },
                  props.children
                );
              }

              if (style == "blockquote" || style == "quote") {
                return h(
                  "blockquote",
                  {
                    className: `${isCollapsed ? "state-collapsed" : ""}`,
                  },
                  props.children
                );
              }

              if (style == "small") {
                return h(
                  "p",
                  {
                    className: `small ${isCollapsed ? "state-collapsed" : ""}`,
                  },
                  props.children
                );
              }

              return h(
                "p",
                {
                  className: `${isCollapsed ? "state-collapsed" : ""}`,
                },
                props.children
              );
            },

            ads: (props) => {
              return h(
                "div",
                {
                  className: `ad ${isCollapsed ? "state-collapsed" : ""}`,
                },
                h("img", {
                  src: this.getImageUrl(props.node, "mainImage"),
                  alt: props.node.title,
                  title: props.node.title,
                })
              );
            },

            alignedImage: () => null,

            image: (props) =>
              h(
                "figure",
                {
                  className: isCollapsed ? "state-collapsed" : "",
                },
                h("img", {
                  src: this.getImageAsset(props.node),
                })
              ),

            orderedListItemWrapper: (props) =>
              h(
                "ol",
                {
                  className: isCollapsed ? "state-collapsed" : "",
                },
                props.children
              ),

            unOrderedListItemWrapper: (props) =>
              h(
                "ul",
                {
                  className: isCollapsed ? "state-collapsed" : "",
                },
                props.children
              ),
          },
          marks: {
            internalLink: ({ mark, children }) =>
              h(
                "a",
                {
                  href: `/post/${
                    mark.slug && mark.slug.current ? mark.slug.current : mark.id
                  }/`,
                },
                children
              ),
            sup: ({ children }) => h("sup", children),
            sub: ({ children }) => h("sub", children),
            center: ({ children }) => h("center", children),
          },
        },
        projectId: process.env.VUE_APP_SANITY_PROJECT_ID,
        dataset: process.env.VUE_APP_SANITY_DATASET,
      });
    },

    getImageUrl(source, img = "image", width = undefined, height = undefined) {
      if (!source[img]) {
        return "";
      }

      const sourceHeight = source[img].asset?.metadata?.dimensions?.height;
      const sourceWidth = source[img].asset?.metadata?.dimensions?.width;

      if (sourceHeight >= 1080 && sourceWidth >= 1920) {
        height = 1080;
        width = undefined;
      }

      return imageBuilder
        .image(source[img])
        .width(width)
        .height(height)
        .format("webp");
    },

    getImageAsset(asset, width = undefined, height = undefined) {
      if (!asset) {
        return "";
      }

      const sourceHeight = asset.asset?.metadata?.dimensions?.height;
      const sourceWidth = asset.asset?.metadata?.dimensions?.width;

      if (sourceHeight >= 1080 && sourceWidth >= 1920) {
        height = 1080;
        width = undefined;
      }

      return imageBuilder
        .image(asset)
        .width(width)
        .height(height);
    },

    getAlignedImageUrl(alignedImage) {
      return this.getImageUrl(
        alignedImage,
        "mainImage",
        undefined,
        alignedImage.adjustment ? 100 : undefined
      );
    },

    handleClick(alignedImage) {
      console.log(alignedImage);
      if (alignedImage.link) {
        window.location.href = alignedImage.link;
      }

      if (!alignedImage.file) {
        return;
      }

      const sanityValues = {
        projectId: process.env.VUE_APP_SANITY_PROJECT_ID,
        dataset: process.env.VUE_APP_SANITY_DATASET,
      };

      const file = getFile(alignedImage.file, sanityValues);
      const url = buildFileUrl(file.asset, sanityValues);

      return window.open(`${url}?dl=`);
    },

    getAlignedImageClasses(alignedImage, inner = false, isCollapsed = false) {
      const shouldSpan = alignedImage.span;
      const background = alignedImage.background;
      const classes = [];

      if (!inner && !alignedImage.wrapper) {
        classes.push(alignedImage.alignment ? alignedImage.alignment : "left");
      } else if (!inner && alignedImage.wrapper) {
        classes.push(
          alignedImage.alignmentWrapper ? alignedImage.alignmentWrapper : "top"
        );
      } else if (alignedImage.adjustment) {
        classes.push("adjusted");
      }

      if (
        (!inner && shouldSpan && background) ||
        (inner && !shouldSpan && background)
      ) {
        classes.push("background", background);
      }

      if (!inner && (alignedImage.file || alignedImage.link)) {
        classes.push("clickable");
      }

      if (isCollapsed) {
        classes.push("state-collapsed");
      }

      return classes.join(" ");
    },

    getAlignedImageWrapperClasses(alignedImageWrapper) {
      const elements = alignedImageWrapper.children.length;

      if (elements === 1) {
        return "col-12";
      } else if (elements >= 2 && elements % 3 === 0) {
        return "col-4";
      } else if (elements >= 2) {
        return "col-6";
      }
    },

    getPdfWrapperClasses(pdfWrapper) {
      const elements = pdfWrapper.children.length;

      if (elements === 1) {
        return "col-12";
      } else if (elements >= 2) {
        return "col-6";
      }
    },

    retrieveTableOfContents() {
      const html = this.render(this.blocks);

      const headers = [];
      const r = new RegExp(/<h2 id="(.*?)"( class="(.*?))?">(.*?)<\/h2>/g);
      let findHeader;
      while (null != (findHeader = r.exec(html))) {
        headers.push({
          index: findHeader[1],
          text: findHeader[4],
        });
      }

      return headers;
    },

    goToFile(child) {
      if (!child.fullFile) {
        return;
      }

      const sanityValues = {
        projectId: process.env.VUE_APP_SANITY_PROJECT_ID,
        dataset: process.env.VUE_APP_SANITY_DATASET,
      };

      const file = getFile(child.fullFile, sanityValues);
      const url = buildFileUrl(file.asset, sanityValues);

      return window.open(url);
    },

    retrieveImage(image) {
      if (!image) {
        return "";
      }

      return imageBuilder.image(image).format("webp");
    },

    formatNumber(n) {
      return `${new Intl.NumberFormat("no-NO", {
        maximumFractionDigits: 0,
        currency: "NOK",
      }).format(n)} kr`;
    },

    goToPrice(link) {
      window.open(link, "_blank");
    },
  },
};
</script>
