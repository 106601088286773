<template>
  <div>
    <template v-for="(container, index) in retrieveContainers(blocks)">
      <div
        v-if="type == 'page' && element !== 'index'"
        class="container page"
        :class="{ onepage: onepage, cloud: index % 2 !== 0 }"
        :key="index"
      >
        <div class="wrapper">
          <div class="grid">
            <div class="col-12">
              <template v-if="index === 0">
                <div v-if="sponsor" class="section full sponsor">
                  <div
                    class="image"
                    v-if="sponsor.logo"
                    :style="{
                      backgroundImage: `url('${getImageUrl(sponsor, 'logo')}')`,
                    }"
                  ></div>
                  <div class="details" :class="{ full: !sponsor.logo }">
                    <p>{{ sponsor.text }}</p>
                  </div>
                </div>
              </template>

              <Block
                :children="container.children"
                :blocks="blocks"
                :index="index"
                :element="element"
                :overwrite="overwrite"
                :onepage="onepage"
              />
            </div>
          </div>
        </div>
      </div>
      <Block
        v-else
        :key="index"
        :children="container.children"
        :blocks="blocks"
        :index="index"
        :element="element"
        :overwrite="overwrite"
        :onepage="onepage"
      />
    </template>
  </div>
</template>

<script>
import Block from "./Block";
import sanity from "../sanity";
import imageUrlBuilder from "@sanity/image-url";

const imageBuilder = imageUrlBuilder(sanity);

export default {
  components: {
    Block,
  },

  props: {
    blocks: {
      type: Array,
      required: true,
    },

    type: {
      type: String,
      required: false,
      default: "",
    },

    element: {
      type: String,
      required: false,
      default: "",
    },

    onepage: {
      type: Boolean,
      required: false,
      default: false,
    },

    overwrite: {
      type: Array,
      required: false,
    },

    sponsor: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
      stateCollapsed: {},
    };
  },

  methods: {
    retrieveContainers(blocks) {
      blocks = this.groupElements(blocks, [
        "category",
        "team",
        "section",
        "pdf",
        "button",
        "price",
      ]);

      blocks = this.groupAlignedImages(blocks);
      blocks = this.groupListItems(blocks);

      let children = [];
      const containers = [];
      blocks.forEach((block) => {
        if (children.length > 0 && block.style === "h2") {
          // Start over
          containers.push({
            children,
          });
          children = [];
        }

        children.push(block);
      });

      // Ensure final container is added
      if (children.length > 0) {
        containers.push({
          children,
        });
      }

      return containers;
    },

    groupListItems(blocks) {
      // Group all list items together to ensure they are all listed in same <ul> or <ol>
      const clonedBlocks = [...blocks];

      let groupOrdered = [];
      let groupOrderedId = 0;
      let groupUnordered = [];
      let groupUnorderedId = 0;

      blocks.forEach((block) => {
        if (
          block._type === "block" &&
          block?.listItem === "number" &&
          groupOrdered.length > 0 &&
          typeof groupOrdered[groupOrderedId] != "undefined"
        ) {
          groupOrdered[groupOrderedId].push(block._key);
        } else if (block._type === "block" && block?.listItem === "number") {
          groupOrdered[groupOrderedId] = [block._key];
        } else if (typeof groupOrdered[groupOrderedId] != "undefined") {
          groupOrderedId++;
        }

        if (
          block._type === "block" &&
          block?.listItem === "bullet" &&
          groupUnordered.length > 0 &&
          typeof groupUnordered[groupUnorderedId] != "undefined"
        ) {
          groupUnordered[groupUnorderedId].push(block._key);
        } else if (block._type === "block" && block?.listItem === "bullet") {
          groupUnordered[groupUnorderedId] = [block._key];
        } else if (typeof groupUnordered[groupUnorderedId] != "undefined") {
          groupUnorderedId++;
        }
      });

      let k = 0;
      groupOrdered.forEach((el) => {
        let children = blocks.filter((b) => el.includes(b._key));
        let firstIndex = blocks.findIndex((b) => b._key === el[0]);

        clonedBlocks[firstIndex] = {
          _key: `orderedListItem-wrapper-${k.toString()}`,
          _type: `orderedListItemWrapper`,
          children: children,
          markDefs: [],
          style: "normal",
        };

        k++;
      });

      k = 0;
      groupUnordered.forEach((el) => {
        let children = blocks.filter((b) => el.includes(b._key));
        let firstIndex = blocks.findIndex((b) => b._key === el[0]);

        clonedBlocks[firstIndex] = {
          _key: `unOrderedListItem-wrapper-${k.toString()}`,
          _type: `unOrderedListItemWrapper`,
          children: children,
          markDefs: [],
          style: "normal",
        };

        k++;
      });

      return clonedBlocks.filter(
        (b) =>
          b._type !== "block" || !["number", "bullet"].includes(b?.listItem)
      );
    },

    groupAlignedImages(blocks) {
      // Specific groupings - need to ensure all images after each other with wrapper field = true
      const clonedBlocks = [...blocks];

      let group = [];
      let lastBlock = "";
      blocks.forEach((block) => {
        if (
          lastBlock === "alignedImage" &&
          block._type === "alignedImage" &&
          block.wrapper &&
          group.length > 0
        ) {
          group[group.length - 1].push(block._key);
        } else if (block._type === "alignedImage" && block.wrapper) {
          group.push([block._key]);
        }

        if (
          block._type != "block" ||
          (block._type == "block" &&
            (!block.children ||
              block.children.length != 1 ||
              block.children[0].text != ""))
        ) {
          lastBlock = block._type;
        }
      });

      let k = 0;
      group.forEach((el) => {
        let children = blocks.filter((b) => el.includes(b._key));
        let firstIndex = blocks.findIndex((b) => b._key === el[0]);

        clonedBlocks[firstIndex] = {
          _key: `alignedImage-wrapper-${k.toString()}`,
          _type: `alignedImageWrapper`,
          children: children,
          markDefs: [],
          style: "normal",
        };

        k++;
      });

      return clonedBlocks.filter(
        (b) => b._type !== "alignedImage" || !b.wrapper
      );
    },

    groupElements(blocks, types) {
      const clonedBlocks = [...blocks];

      types.forEach((type) => {
        let group = [];
        let lastBlock = "";

        blocks.forEach((block) => {
          if (lastBlock === type && block._type === type && group.length > 0) {
            group[group.length - 1].push(block._key);
          } else if (block._type === type) {
            group.push([block._key]);
          }

          if (
            block._type != "block" ||
            (block._type == "block" &&
              (!block.children ||
                block.children.length != 1 ||
                block.children[0].text != ""))
          ) {
            lastBlock = block._type;
          }
        });

        let k = 0;
        group.forEach((el) => {
          let children = blocks.filter((b) => el.includes(b._key));
          let firstIndex = blocks.findIndex((b) => b._key === el[0]);

          clonedBlocks[firstIndex] = {
            _key: `${type}-wrapper-${k.toString()}`,
            _type: `${type}Wrapper`,
            children: children.map((child) => ({
              ...child,
              _count: children.length,
            })),
            markDefs: [],
            style: "normal",
          };

          k++;
        });
      });

      return clonedBlocks.filter((b) => !types.includes(b._type));
    },

    getImageUrl(source, img = "image", width = undefined, height = undefined) {
      if (!source[img]) {
        return "";
      }

      return imageBuilder
        .image(source[img])
        .width(width)
        .height(height)
        .format("webp");
    },
  },
};
</script>
